export const SessionsHelpers = {
  computed: {
    hasQuestionnaires () {
      const sessions = this.$store.getters.userSessions
      let hasQuestionnaires = false

      if (sessions) {
        for (let i = 0; i < sessions.length; i++) {
          const session = sessions[i]
          if (session.questionnaires && session.questionnaires.length) {
            hasQuestionnaires = true
            break
          }
        }
      }

      return !!sessions && hasQuestionnaires
    }
  }
}
