<template>
  <div class="sessions-container no-sessions">
    <div class="sessions-box-container">
      <div class="session-box">
        <div class="box">
          <div class="box-content">
            <div class="image-container">
              <initials-circle
                class="gray-border-circle"
                :first="firstInitial"
                :second="secondInitial"
              />
            </div>
            <div class="text-container">
              <h2><span v-if="!justFinishedSession">{{ $t("greeting") }}</span> {{ greetingName }}</h2>
              <p class="message">
                {{ $t("noSessionsMessageLineOne") }}
              </p>
              <p class="message">
                {{ $t("noSessionsMessageLineTwo") }}
              </p>
            </div>
          </div>
          <div class="actions-container">
            <button
              class="btn btn-primary no-sessions-btn"
              @click.prevent="singOut"
            >
              <span> {{ $t("noSessionsLogOutButtonText") }} </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InitialsCircle from '@/components/common/InitialsCircle'
import { SessionsHelpers } from '@/mixins/SessionsHelpers'
import { mapGetters } from 'vuex'
import { Helpers } from '@/mixins/Helpers'

export default {
  name: 'NoSessions',
  components: {
    InitialsCircle
  },
  mixins: [SessionsHelpers, Helpers],
  data () {
    return {
      sessions: []
    }
  },
  computed: {
    ...mapGetters({
      justFinishedSession: 'getJustFinishedSession'
    }),
    greetingName () {
      return (this.user.firstName) ? this.user.firstName : this.user.username
    }
  },
  created () {
    this.$store.dispatch('GET_SESSIONS').then(() => {
      if (this.hasQuestionnaires) {
        this.$router.push({ name: 'Session' })
      }
    })
    this.user = this.$store.getters.loggedInUser
  },
  beforeDestroy () {
    this.$store.dispatch('JUST_FINISHED_SESSION', false)
  },
  methods: {
    singOut () {
      this.$store.dispatch('SIGN_OUT').then(() => this.$router.push('/security/login'))
    }
  }
}
</script>
